/**
 * Remove errors.
 */
function removeErrors() {
    $('.has-error').removeClass('has-error');
}

/**
 * Show error.
 *
 * @param name
 * @param error
 */
function showError(name, error) {
    var input = $('form').find('input[name="' + name + '"]');

    if (input.length > 0) {
        var div = input.parent('div');

        if (div.hasClass('input-group')) {
            div.parent('div');
        }

        div.addClass('has-error');
        console.log(error);
    }

    var select = $('form').find('select[name="' + name + '"]');

    if (select.length > 0) {
        var div = select.parent('div').parent('div');
        div.addClass('has-error');
        console.log(error);
    }
}

/**
 * Link back.
 */
function linkBack() {
    var referrer = document.referrer;
    window.location.href = referrer;
}